import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../components/Index/Banner"
import OurProducts from "../components/Index/OurProducts"
import Kurznews from "../components/Index/Kurznews"
import OurBlog from "../components/Index/OurBlog"
import Seo from "../components/App/Seo"
import {graphql} from "gatsby";

const Home = ({data}) => {
  return (
    <Layout>
      <Seo title={"Versandlogistiksoftware :: Lagersoftware"}
           description={"NETVERSYS ist unser modernes und cloudfähiges " +
           "Versandsystem. NETSTORSYS ist das Warehouse Management System, " +
           "dass die Supply Chain optimiert."}
           image={"/images/og/logentis-main-og.png"}
      />
      <Navbar />
      <Banner />
      <OurProducts />
      <Kurznews />
      <OurBlog lastPosts={data.allMarkdownRemark.edges}/>
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query last3BlogPosts {
     allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC },
        limit: 3,
        filter: { fields: { collection: { eq: "blog" }}, frontmatter: {disabled: {eq: false}}}
      )
      {
        edges {
            node {
              id
              html
              excerpt(pruneLength: 400)
              fields {
                slug
              }
              frontmatter {
                date(formatString: "DD.MM.YYYY")
                title
                featuredimage {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                authorFull {
                    name
                    authorimage {
                        childImageSharp {
                            gatsbyImageData(width: 40)
                        }
                    }
                }
              }
            }
        }
     }
  }
`

export default Home
