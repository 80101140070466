import React from "react"
import { Badge } from "react-bootstrap"

const KurznewsCard = ({news}) => {
  return (
    <div className="single-services-item-box h-100">
      {/*<div className="mb-2">
        <FontAwesomeIcon icon={faNewspaper} size={"3x"} />
      </div>*/}

      <h2 className={"h4"}>{news.title}</h2>
      <p className={"mb-0"} style={{fontSize:"14px"}}>Veröffentlicht: {news.date} </p>
      {news.tags.map((tag) => (
        <Badge key={tag} pill variant="primary">{tag}</Badge>
      ))}
      <hr />
      <p style={{textAlign: "justify"}}>{news.news}</p>
      {
        news.moreinfolink && (<p><a href={news.moreinfolink} target="_blank" rel="noreferrer" className={"text-primary"}>{news.moreinfotext}</a></p>)
      }
    </div>
  )
}

export default KurznewsCard
