import React from "react"
import { Col, Row, Container } from "react-bootstrap"
import { graphql, StaticQuery, Link } from "gatsby"
import KurznewsCard from "../kurznews/KurznewsCard"

const Kurznews = ({ data }) => {
  return (
    <section className="services-area pt-100 pb-70 bg-f1f8fb">
      <Container>
        <div className="section-title">
          <h2>Neuigkeiten</h2>
        </div>

        <Row>
          {data.allKurznewsJson.nodes.map((news) => (
            <Col key={news.id} lg={4} sm={6} className={"mt-4"}>
              <KurznewsCard news={news}/>
            </Col>
          ))}
        </Row>
        <div className={"mt-4"}>
          <Link to={"/kurznewsarchiv/"}>Zum Kurzunewsarchiv</Link>
        </div>
      </Container>

    </section>
  )
}

const Main = () => (
  <StaticQuery
    query={graphql`
      query RecentPostsQuery {
        allKurznewsJson (
          sort: { fields: [date], order: DESC },
          limit: 3
        ) {
            nodes {
                id
                date(formatString: "DD.MM.YYYY")
                tags
                news
                title
                moreinfolink
                moreinfotext
            }
        }
      }
    `}
    render={(data) => <Kurznews data={data} />}
  />
)

export default Main;
