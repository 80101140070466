import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const BlogCard = ({ post, grid = "col-lg-4 col-md-6" }) => {
  return (
    <div className={grid}>
      <div className="single-blog-post bg-fffbf5">
        <div className="post-image">
          <Link to={post.fields.slug}>
            <GatsbyImage alt={post.frontmatter.title}
                         image={getImage(post.frontmatter.featuredimage)}/>
          </Link>
        </div>

        <div className="post-content">
          <ul className="post-meta d-flex justify-content-between align-items-center">
            <li>
              <div className="d-flex align-items-center">
                {
                  post.frontmatter.authorFull.authorimage &&
                  <GatsbyImage alt={post.frontmatter.authorFull.name}
                               image={getImage(post.frontmatter.authorFull.authorimage)}
                                className={"ml-1 rounded-circle"}
                  />
                }
                <span className={"ml-2"}>{post.frontmatter.authorFull.name}</span>
              </div>
            </li>
            <li>
              <i className="okicon-calendar" /> {post.frontmatter.date}
            </li>
          </ul>
          <h3>
            <Link to={post.fields.slug}
                  className={"neutral-link-optional-color"}
                  style={{ textDecoration: "none" }}>
              {post.frontmatter.title}
            </Link>
          </h3>
        </div>
      </div>
    </div>
  )
}

export default BlogCard
